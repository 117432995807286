import {I18N} from "aurelia-i18n";
import {autoinject} from "aurelia-framework";
import {FhirService} from "../../../resources/services/FhirService";
import {bindable} from "aurelia-templating";
import {PatientItem} from "../../../resources/classes/Patient/PatientItem";
import {BasicForm} from "../../../resources/elements/BasicForm";

@autoinject
export class MedicationProvision {
    @bindable patient;

    constructor(protected i18n: I18N, protected fhirService: FhirService) {
        this.patient = PatientItem.SelectedPatient;
    }

    attached() {
        document.body.classList.add("no-toolbar-window");
        BasicForm.pageTitle = "Mdm Information";
    }

    detached() {
        document.body.classList.remove("no-toolbar-window");
    }
}
