import {ConfigService} from "../../../resources/services/ConfigService";
import {PatientItem} from "../../../resources/classes/Patient/PatientItem";
import {PatientService} from "../../../resources/services/PatientService";
import {inject} from "aurelia-framework";
import {PatientChangeNotifier} from "../../../resources/services/PatientChangeNotifier";
import {DialogService} from "aurelia-dialog";
import {FhirService} from "../../../resources/services/FhirService";
import {I18N} from "aurelia-i18n";
import {DialogMessages} from "../../../resources/services/DialogMessages";
import {Router} from "aurelia-router";
import {IFormSetting} from "../../../resources/classes/IFormSettings";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {allergyAdd} from "./allergy-add";
import {Allergy, IAllergy} from "../../../resources/classes/allergy";
import {NitTools} from "../../../resources/classes/NursitTools";

@inject(PatientChangeNotifier, DialogService, FhirService, I18N, PatientService, DialogMessages, Router)
export class allergyMain {
    valueSetId : string;
    patient : PatientItem
    encounterId : string;
    cfg : IFormSetting;
    allergyIntolerances = [];
    allergies : IAllergy[] = [];
    static popupShowing : boolean = false;
    dialogService : DialogService;
    readonly : boolean = false;

    constructor(protected notifier: PatientChangeNotifier, dialogService : DialogService, protected fhirService: FhirService,
                protected i18n: I18N, protected patientService: PatientService, protected dialogMessages: DialogMessages,
                protected router: Router) {

        this.dialogService = dialogService;
        if (ConfigService.Debug)
            window["allergy"] = this;
    }

    protected async attached() {
        if (!this.patient || !this.valueSetId || !this.cfg) return;
        await this.loadDataAsync();
    }

    protected async activate(params) {
        try {
            this.encounterId = params.id||params.encounterId;
            if (!this.encounterId)
                throw 'Expected Parameter params.id for the encounter-id not found';

            this.patient = await this.patientService.fetch(this.encounterId);
            if (!this.patient)
                throw `No patient loaded for encounter-id in param "${this.encounterId}"`;

            await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);

            this.cfg = ConfigService.GetFormSettings("allergy");
            if (!this.cfg)
                throw 'No Config for route "allergy" found'

            // BasicForm.pageTitle = this.i18n.tr(this.cfg.title);
            this.valueSetId = this.cfg.settings?.valueSetId;
            if (typeof this.cfg.settings?.readonly === "boolean")
                this.readonly = this.cfg.settings.readonly;

            if (!this.valueSetId)
                throw 'No ValueSet-Id for allergies provided. Has to be provided in the "settings" section for the route "allergy"';
        }
        catch (e) {
            console.warn(e);
        }
    }

    async loadDataAsync() {
        if (!this.patient)
            return;

        RuntimeInfo.IsLoading = true;
        try {
            await Allergy.LoadForPatient(this.patient, this.fhirService, this.i18n);
            this.allergies = NitTools.Clone(this.patient.allergies);
        }
        finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    async askDeleteAllergy(allergy) {
        if (!allergy) {
            console.warn('No Allergy provided to delete');
            return;
        }

        const msg = this.i18n.tr('allergy_confirm_delete', allergy);
        DialogMessages.Dialog(this.dialogService, msg, this.i18n.tr('confirm'), this.i18n.tr('delete'), this.i18n.tr('abort'))
            .whenClosed(async o => {
                if (o.wasCancelled)
                    return;

                try {
                    RuntimeInfo.IsLoading = true;
                    await this.fhirService.deleteUrl(`AllergyIntolerance/${allergy.id}`);
                    await this.loadDataAsync();
                }
                finally {
                    RuntimeInfo.IsLoading = false;
                }
            })
            .catch();
    }

    async openEditView(allergy) {
        if (allergyMain.popupShowing) return;   // check this 1st to avoid double click displaying multiple instances
        allergyMain.popupShowing = true;
        
        // noinspection ES6MissingAwait
        this.dialogService.open({
            viewModel: allergyAdd,
            model: { patient: this.patient, valueSetId: this.valueSetId, service : this.fhirService, allergy: allergy },
            lock: true
        })
            .whenClosed(async e => {
                if (e.wasCancelled)
                    return;

                try {
                    await this.fhirService.update(e.output);
                    await this.loadDataAsync();
                }
                catch (e) {
                    this.dialogMessages.prompt(e.message||e, this.i18n.tr("error"), true);
                }
            })
    }

    async openAddView() {
        if (allergyMain.popupShowing) return;   // check this 1st to avoid double click displaying multiple instances
        allergyMain.popupShowing = true;
        // noinspection ES6MissingAwait
        this.dialogService.open({
            viewModel: allergyAdd,
            model: { patient: this.patient, valueSetId: this.valueSetId, service : this.fhirService },
            lock: true
        })
            .whenClosed(async e => {
                if (e.wasCancelled)
                    return;

                try {
                    await this.fhirService.update(e.output);
                    await this.loadDataAsync();
                }
                catch (e) {
                    this.dialogMessages.prompt(e.message||e, this.i18n.tr("error"), true);
                }
            })
    }
}
